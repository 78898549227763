import React from "react";
import { Button, Center, Icon, Text, VStack } from "@chakra-ui/react";
import { FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import video from "../../Images/introvideo.mp4";
import { SlArrowDown } from "react-icons/sl";
import { motion } from "framer-motion";

const Hero = () => {
  const navigate = useNavigate();

  return (
    <VStack
      id="home"
      w={"full"}
      h={["60vh", "70vh", "50vh", "50vh", "100vh", "100vh"]}
      bg={"transparent"}
      align={"flex-start"}
      justify={["none", "none", "none", "none", "center", "center"]}
      pt={[52, 52, 52, 52, 0, 0]}
      spacing={8}
      position={"relative"}
      zIndex={0}
    >
      <Center
        w={"100vw"}
        h={"full"}
        pos={"absolute"}
        top={0}
        left={0}
        zIndex={-1}
        filter="auto"
        brightness="30%"
        bg="blue"
      >
        <video
          autoPlay={true}
          muted
          loop
          width={"100%"}
          style={{ minHeight: "100%", objectFit: "cover" }}
          height={"100%"}
        >
          <source src={video} type="video/mp4" />
        </video>
      </Center>

      <VStack
        spacing={[2, 2, 4, 4, 8, 8]}
        pl={[6, 6, 24, 24, 44, 44]}
        align={"flex-start"}
      >
        <Text
          w={["xs", "sm", "md", "md", "lg", "lg"]}
          fontFamily={"Montserrat"}
          fontSize={[26, 26, 36, 36, 46, 46]}
          fontWeight={"black"}
          color={"#fff"}
          textTransform={"uppercase"}
          lineHeight={"initial"}
        >
          “We have an idea!”
          <Text
            as={"span"}
            fontFamily={"Montserrat"}
            fontSize={[16, 16, 20, 20, 30, 30]}
            fontWeight={"medium"}
            color={"#fff"}
            textTransform={"initial"}
          >
            {" "}
            <br />
            We have an
          </Text>
          <Text
            as={"span"}
            fontFamily={"Piedra"}
            fontSize={[16, 16, 20, 20, 30, 30]}
            fontWeight={"medium"}
            color={"#fff"}
            textTransform={"initial"}
          >
            {" "}
            innovative, creative
          </Text>
          <Text
            as={"span"}
            fontFamily={"Montserrat"}
            fontSize={[16, 16, 20, 20, 30, 30]}
            fontWeight={"medium"}
            color={"#fff"}
            textTransform={"initial"}
          >
            {" "}
            idea just for you. For each client we try to understand their needs
            and goals to create the
          </Text>
          <Text
            as={"span"}
            fontFamily={"Piedra"}
            fontSize={[16, 16, 20, 20, 30, 30]}
            fontWeight={"medium"}
            color={"#fff"}
            textTransform={"initial"}
          >
            {" "}
            unique ideal solution.
          </Text>
        </Text>

        <Button
          transition={"ease-in-out .3s"}
          bg={"#B81C41"}
          rounded={"full"}
          fontSize={[13, 13, 15, 15, 15, 15]}
          fontWeight={"medium"}
          color={"#fff"}
          textTransform={"uppercase"}
          px={"6"}
          rightIcon={<FaArrowRight />}
          onClick={() => navigate("/contact")}
          _hover={{
            bg: "#A01031",
          }}
        >
          Partner with us
        </Button>
      </VStack>

      {/* TODO: DOWN ARROW (animation) */}
      <VStack
        pos={"absolute"}
        bottom={[2, 4, 6, 8, 10, 10]}
        sx={{
          left: "calc(50% - 6px)",
        }}
        spacing={0}
      >
        <motion.div
          animate={{ opacity: [0, 0, 0, 1, 0], translateY: [-20, 20] }}
          transition={{ repeat: Infinity, duration: 2.1 }}
        >
          <Icon as={SlArrowDown} w={"6"} h={"6"} color={"#fff"} />
        </motion.div>
        <motion.div
          animate={{ opacity: [0, 0, 1, 0, 0], translateY: [-20, 20] }}
          transition={{ repeat: Infinity, duration: 2.1 }}
          style={{
            marginTop: -15,
          }}
        >
          <Icon as={SlArrowDown} w={"6"} h={"6"} color={"#fff"} />
        </motion.div>
        <motion.div
          animate={{ opacity: [0, 1, 0, 0, 0], translateY: [-20, 20] }}
          transition={{ repeat: Infinity, duration: 2.1 }}
          style={{
            marginTop: -15,
          }}
        >
          <Icon as={SlArrowDown} w={"6"} h={"6"} color={"#fff"} />
        </motion.div>
      </VStack>
    </VStack>
  );
};

export default Hero;
