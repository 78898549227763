import React from "react";
import { Text, VStack, Image, Box, HStack, GridItem } from "@chakra-ui/react";

function Title({ title }) {
  return (
    <VStack spacing={1}>
      <Text
        color={"white"}
        fontSize={[30, 30, 36, 36, 43, 43]}
        fontWeight={"semibold"}
      >
        {title}
      </Text>
      <Box w="55%" h="2px" bg="#B81C41"></Box>
    </VStack>
  );
}

export default Title;
