import React from "react";
import { Text, VStack, Image, Box, HStack, SimpleGrid } from "@chakra-ui/react";
import about1 from "../../Images/icons/about/about1.png";
import about2 from "../../Images/icons/about/about2.png";
import about3 from "../../Images/icons/about/about3.png";

const About = () => {
  const data = [
    {
      title: "Mission",
      desc: "Our mission is to provide the best customer results in a possible short time period.",
      img: about1,
    },
    {
      title: "Vision",
      desc: "Our vision is to create a business world full of prosperity, meaning, and connection for all.",
      img: about2,
    },
    {
      title: "Values",
      desc: "Quality, creativity, speed, teamwork.",
      img: about3,
    },
  ];

  return (
    <VStack
      id="about"
      w={["90%", "90%", "90%", "85%", "55%", "55%"]}
      minH={["100vh", "65vh", "65vh", "100vh", "100vh", "100vh"]}
      align={"center"}
      justify={"center"}
      py={20}
    >
      <Text fontSize={[30, 30, 36, 36, 43, 43]} fontWeight={"semibold"}>
        About us
      </Text>

      <VStack mt={4} gap={6}>
        <Text
          textAlign={"center"}
          fontWeight="medium"
          fontSize={[15, 15, 15, 15, 17, 17]}
        >
          Our story began as a PR agency and a video studio in 2021. Since then
          we have developed into the creative media company specializing in
          video production, digital marketing, marketing consulting, graphic
          designing and public relations.
        </Text>

        <Box w="50%" h="2px" bg="#B81C41" mt="2"></Box>
      </VStack>

      {/* <HStack w={"full"} mt={20} gap={8}> */}
      <SimpleGrid w={"full"} columns={[1, 2, 3]} mt={20} gap={8}>
        {data.map((e, i) => (
          <VStack
            key={i}
            align={"center"}
            justify={"space-between"}
            sx={{ boxShadow: "0 0 10px 5px rgba(0, 0, 0, 0.04)" }}
            py={8}
            px={10}
            rounded="xl"
            w="full"
            h="64"
            spacing={4}
          >
            <Text fontSize={[23, 23, 23, 23, 25, 25]} fontWeight={"semibold"}>
              {e.title}
            </Text>

            <Text
              textAlign={"center"}
              lineHeight={1}
              fontSize={[15, 15, 15, 15, 17, 17]}
              fontWeight={"medium"}
            >
              {e.desc}
            </Text>

            <Image src={e.img} w={10} h={10} objectFit={"contain"} />
          </VStack>
        ))}
      </SimpleGrid>
      {/* </HStack> */}
    </VStack>
  );
};

export default About;
