import React, { useEffect, useState } from "react";
import { VStack, Image, GridItem, Grid, Link } from "@chakra-ui/react";
import Title from "../../components/Title";
import picture1 from "../../Images/Picture1.png";
import picture2 from "../../Images/Picture2.png";
import picture3 from "../../Images/Picture3.png";
import picture4 from "../../Images/Picture4.png";
import picture5 from "../../Images/Picture5.png";
import picture6 from "../../Images/Picture6.png";
import picture7 from "../../Images/Picture7.png";
import picture8 from "../../Images/Picture8.png";
import picture9 from "../../Images/Picture9.png";
import picture10 from "../../Images/Picture10.png";
import picture11 from "../../Images/Picture11.png";
import picture12 from "../../Images/Picture12.png";
import picture13 from "../../Images/Picture13.png";
import picture14 from "../../Images/Picture14.png";
import picture15 from "../../Images/Picture15.png";

function WhatWeDid() {
  const [isHover, setIsHover] = useState("");

  const [data, setData] = useState([
    {
      img: picture1,
      toLink:
        "https://www.facebook.com/100089128797811/videos/pcb.395936986720591/1438478100134248",
      rowSpan: 1,
    },
    {
      img: picture5,
      toLink:
        "https://www.facebook.com/photo/?fbid=395937383387218&set=pcb.395937443387212",
      rowSpan: 1,
    },
    {
      img: picture8,
      toLink:
        "https://www.facebook.com/NestPropertyService/videos/394678190035786",
      rowSpan: 2,
    },
    {
      img: picture15,
      toLink:
        "https://www.facebook.com/100089128797811/videos/pcb.395937133387243/1101676941111544",
      rowSpan: 1,
    },
    {
      img: picture9,
      toLink:
        "https://www.facebook.com/100089128797811/videos/pcb.395934883387468/1887356388385300",
      rowSpan: 1,
    },
    {
      img: picture3,
      toLink:
        "https://www.facebook.com/Ideaplusmediacompany/videos/1113058893073061",
      rowSpan: 2,
    },
    {
      img: picture10,
      toLink:
        "https://www.facebook.com/100089128797811/videos/pcb.395937133387243/1101676941111544",
      rowSpan: 1,
    },
    {
      img: picture4,
      toLink: "https://youtu.be/UeyEW0dmLC0?si=ImSA9gQHKXANJ0SK",
      rowSpan: 1,
    },
    {
      img: picture12,
      toLink:
        "https://www.facebook.com/photo?fbid=395937383387218&set=pcb.395937443387212",
      rowSpan: 1,
    },
    {
      img: picture13,
      toLink:
        "https://www.facebook.com/photo?fbid=395937383387218&set=pcb.395937443387212",
      rowSpan: 1,
    },
    {
      img: picture6,
      toLink: "https://youtu.be/82keM5dJEvI?si=uDoDaxXoHjwL10x5",
      rowSpan: 1,
    },
    {
      img: picture11,
      toLink: "https://youtu.be/ilYlAcwHaS0?si=Sp4hIGJ7PIZe7lwx",
      rowSpan: 1,
    },
    {
      img: picture14,
      toLink:
        "https://www.facebook.com/100089128797811/videos/pcb.395937926720497/1113058893073061",
      rowSpan: 2,
    },
    {
      img: picture2,
      toLink:
        "https://www.facebook.com/transportationpolice/videos/812406023924240",
      rowSpan: 1,
    },
    {
      img: picture7,
      toLink: "https://youtu.be/LrkHnP2UDjM?si=1E2hFRrpYuwweVun",
      rowSpan: 1,
    },
  ]);

  useEffect(() => {
    if (window.innerWidth < 1280) {
      setData([
        {
          img: picture1,
          toLink:
            "https://www.facebook.com/100089128797811/videos/pcb.395936986720591/1438478100134248",
          rowSpan: 1,
        },
        {
          img: picture5,
          toLink:
            "https://www.facebook.com/photo/?fbid=395937383387218&set=pcb.395937443387212",
          rowSpan: 1,
        },
        {
          img: picture15,
          toLink:
            "https://www.facebook.com/100089128797811/videos/pcb.395937133387243/1101676941111544",
          rowSpan: 1,
        },
        {
          img: picture9,
          toLink:
            "https://www.facebook.com/100089128797811/videos/pcb.395934883387468/1887356388385300",
          rowSpan: 1,
        },
        {
          img: picture2,
          toLink:
            "https://www.facebook.com/transportationpolice/videos/812406023924240",
          rowSpan: 1,
        },
        {
          img: picture3,
          toLink:
            "https://www.facebook.com/Ideaplusmediacompany/videos/1113058893073061",
          rowSpan: 1,
        },
        {
          img: picture10,
          toLink:
            "https://www.facebook.com/100089128797811/videos/pcb.395937133387243/1101676941111544",
          rowSpan: 1,
        },
        {
          img: picture4,
          toLink: "https://youtu.be/UeyEW0dmLC0?si=ImSA9gQHKXANJ0SK",
          rowSpan: 1,
        },
        {
          img: picture12,
          toLink:
            "https://www.facebook.com/photo?fbid=395937383387218&set=pcb.395937443387212",
          rowSpan: 1,
        },
        {
          img: picture13,
          toLink:
            "https://www.facebook.com/photo?fbid=395937383387218&set=pcb.395937443387212",
          rowSpan: 1,
        },

        {
          img: picture11,
          toLink: "https://youtu.be/ilYlAcwHaS0?si=Sp4hIGJ7PIZe7lwx",
          rowSpan: 1,
        },
        {
          img: picture14,
          toLink:
            "https://www.facebook.com/100089128797811/videos/pcb.395937926720497/1113058893073061",
          rowSpan: 1,
        },

        {
          img: picture7,
          toLink: "https://youtu.be/LrkHnP2UDjM?si=1E2hFRrpYuwweVun",
          rowSpan: 1,
        },
        {
          img: picture6,
          toLink: "https://youtu.be/82keM5dJEvI?si=uDoDaxXoHjwL10x5",
          rowSpan: 1,
        },
      ]);
    }
  }, []);

  return (
    <VStack
      id="wwdi"
      w="full"
      minH="100vh"
      h={"max-content"}
      spacing={20}
      pt={[20, 20, 20, 20, 40, 40]}
      pb={"20"}
      overflow={"hidden"}
    >
      <Title title={"What we did?"} />

      <Grid
        w={["90%", "90%", "90%", "55%", "55%", "55%"]}
        h="max-content"
        templateRows={[
          "repeat(1, 1fr)",
          "repeat(1, 1fr)",
          "repeat(1, 1fr)",
          "repeat(6, 1fr)",
          "repeat(6, 1fr)",
          "repeat(6, 1fr)",
        ]}
        templateColumns={[
          "repeat(2, 1fr)",
          "repeat(2, 1fr)",
          "repeat(2, 1fr)",
          "repeat(3, 1fr)",
          "repeat(3, 1fr)",
          "repeat(3, 1fr)",
        ]}
        gap={1}
      >
        {data.map((item, index) => (
          <GridItem
            transition={"ease-in-out .3s"}
            key={index}
            w={"full"}
            h={
              window.innerWidth < 480
                ? "170px"
                : item.rowSpan === 2
                ? "calc((2 * var(--chakra-sizes-xs)) + 4px)"
                : "var(--chakra-sizes-xs)"
            }
            rowSpan={item.rowSpan}
            cursor={"pointer"}
            pos={"relative"}
            _after={{
              content: '""',
              pos: "absolute",
              top: 5,
              // right: 0,
              left: "20px",
              w: "1px",
              h: "0",
              bg: "#fff",
              transition: "ease-in-out .3s",
              zIndex: 999,
            }}
            _before={{
              content: '""',
              pos: "absolute",
              top: 5,
              // right: 0,
              left: "20px",
              w: "0",
              h: "1px",
              bg: "#fff",
              transition: "ease-in-out .3s",
              zIndex: 999,
            }}
            onMouseOut={() => setIsHover("")}
            onMouseOver={() => setIsHover(item.img)}
            _hover={{
              // backdropBrightness: "30%",
              // contrast: "30%",
              _before: {
                width: "calc(100% - 40px)",
                left: "20px",
                bottom: "auto",
              },
              _after: {
                height: "calc(100% - 40px)",
                left: "20px",
                right: "auto",
              },
            }}
          >
            <Link isExternal href={item.toLink}>
              <Image
                transition={"ease-in-out .3s"}
                src={item.img}
                w={"full"}
                h={"full"}
                objectFit={"cover"}
                filter={"auto"}
                brightness={item.img === isHover ? "30%" : "100%"}
                // transform={item.img === isHover ? "scale(1.1)" : "scale(1)"}
              />
            </Link>
          </GridItem>
        ))}
      </Grid>
    </VStack>
  );
}

export default WhatWeDid;
