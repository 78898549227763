import React from "react";
import { VStack } from "@chakra-ui/react";
import Hero from "./mainsections/Hero";
import About from "./mainsections/About";
import Header from "../components/header/Header";
import WhatWeDo from "./mainsections/WhatWeDo";
import Footer from "./mainsections/Footer";
import WhatWeDid from "./mainsections/WhatWeDid";
import Partner from "./mainsections/Partner";

const Main = () => {
  return (
    <VStack
      w={"full"}
      h={"full"}
      bg={"#fff"}
      spacing={0}
      pos={"relative"}
      overflowX={"hidden"}
    >
      <Header />
      <Hero />
      <About />
      <VStack
        w={"full"}
        // bgGradient="linear(to-tr, #B81C41 -20%, #030722 25% )"
        sx={{
          backgroundImage:
            "radial-gradient(farthest-side at -200% 50%,#B81C41,#030722)",
        }}
        spacing={0}
        overflowX={"hidden"}
      >
        <WhatWeDo />
        <Partner />
        <WhatWeDid />
      </VStack>
      <Footer />
    </VStack>
  );
};

export default Main;
