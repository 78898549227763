import React from "react";
import {
  Flex,
  HStack,
  Icon,
  Image,
  Link,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import fimg from "../../Images/image.png";
import logo from "../../Images/logo-white.png";
import { FiMapPin, FiPhone, FiYoutube } from "react-icons/fi";
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import { FaBehance } from "react-icons/fa6";

const Footer = () => {
  const socialData = [
    {
      icon: FaFacebookF,
      toLink: "https://www.facebook.com/Ideaplusmediacompany?mibextid=ZbWKwL",
    },
    {
      icon: FaInstagram,
      toLink:
        "https://www.instagram.com/ideaplus.studio?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==",
    },
    {
      icon: FiYoutube,
      toLink: "https://youtube.com/@ideaagency?si=dB5KUtRp3j5NCeOG",
    },
    {
      icon: FaBehance,
      toLink:
        "https://www.behance.net/ideaplusagency?fbclid=IwZXh0bgNhZW0CMTAAAR0xKNjuJ3Gnr1nDvfgKUI5f7YGe9SNUHNSMr-rgiKkdCrGp6-AjQGS2r4Y_aem_AeT3tq2asFRsYhMS_0Vtlx98OfJSEgLa6Qz55W8Bj2DbHprAeEmW2sxyQTzZYBPDgcPJBASMCFXTUkWeLA9UX0XJ&",
    },
  ];

  return (
    <VStack
      w={"full"}
      // minH={"100vh"}
      align={"center"}
      justify={"flex-start"}
      spacing={0}
      bgGradient="linear(to-tr, #B81C41 -20%, #030722 30% )"
    >
      <VStack w={"full"} pos={"relative"}>
        <Image src={fimg} w={"full"} h={"500px"} objectFit={"cover"} />
        <VStack
          w={["xs", "md", "lg", "xl", "xl", "xl"]}
          pos={"absolute"}
          bottom={"-10"}
          left={"auto"}
          borderWidth={"thin"}
          backdropFilter="auto"
          backdropBlur="10px"
          py={"8"}
          pl={"16"}
          pr={"8"}
          roundedLeft={"full"}
          textAlign={"end"}
          align={"flex-end"}
          spacing={0}
        >
          <Text
            color={"#fff"}
            fontWeight={"medium"}
            fontSize={[17, 17, 17, 19, 19, 19]}
          >
            “Good marketing makes the company look smart. Great marketing makes
            the customer feel smart.”
          </Text>
          <Text
            color={"#fff"}
            fontWeight={"medium"}
            fontSize={[21, 21, 21, 23, 23, 23]}
          >
            - Joe Chernov
          </Text>
        </VStack>
      </VStack>
      <VStack
        w={["full", "full", "full", "6xl", "6xl", "6xl"]}
        py={"32"}
        spacing={10}
        px={[6, 6, 6, 0, 0, 0]}
      >
        <Flex
          align={"center"}
          w="full"
          _before={{
            content: '""',
            borderBottom: "1px solid",
            borderColor: "#8A8A8A",
            flexGrow: 1,
            mr: { base: 4, md: 12 },
          }}
          _after={{
            content: '""',
            borderBottom: "1px solid",
            borderColor: "#8A8A8A",
            flexGrow: 1,
            ml: { base: 4, md: 12 },
          }}
        >
          <Image src={logo} w={[36, 36, 40, 40, 44, 44]} />
        </Flex>

        <Stack
          direction={["column", "column", "column", "row", "row", "row"]}
          w={"full"}
          align={[
            "flex-start",
            "flex-start",
            "flex-start",
            "center",
            "center",
            "center",
          ]}
          justify={"center"}
          spacing={[8, 8, 8, 2, 2, 2]}
        >
          <HStack w={"full"} spacing={4}>
            <Icon as={FiMapPin} w={"5"} h={"5"} color={"#B81C41"} />
            <Text color={"#fff"} fontWeight={"medium"} fontSize={15}>
              MGG Properties building, #201 Chin Van Khanddorj street, 2nd
              khoroo, Sukhbaatar district Ulaanbaatar,Mongolia,14251
            </Text>
          </HStack>
          <HStack
            w={"full"}
            spacing={4}
            align={"center"}
            justify={[
              "flex-start",
              "flex-start",
              "flex-start",
              "center",
              "center",
              "center",
            ]}
          >
            <Icon as={FiPhone} w={"5"} h={"5"} color={"#B81C41"} />
            <VStack spacing={0}>
              <Text color={"#fff"} fontWeight={"medium"} fontSize={15}>
                +976 99199997
              </Text>
              <Text color={"#fff"} fontWeight={"medium"} fontSize={15}>
                +976 90794474
              </Text>
            </VStack>
          </HStack>
          <HStack
            w={"full"}
            spacing={10}
            align={"center"}
            justify={[
              "center",
              "center",
              "center",
              "flex-end",
              "flex-end",
              "flex-end",
            ]}
          >
            {socialData.map((e, i) => (
              <Link key={i} href={e.toLink} h={"7"} isExternal>
                <Icon
                  transition={"ease-in-out .3s"}
                  as={e.icon}
                  w={"7"}
                  h={"7"}
                  color={"#B81C41"}
                  _hover={{
                    color: "#fff",
                  }}
                  cursor={"pointer"}
                />
              </Link>
            ))}
          </HStack>
        </Stack>
      </VStack>
    </VStack>
  );
};

export default Footer;
