import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  HStack,
  Icon,
  Image,
  Link,
  Select,
  SimpleGrid,
  Stack,
  Text,
  Textarea,
  useToast,
  VStack,
} from "@chakra-ui/react";
import partner1 from "../Images/partner-white/partner1.png";
import partner2 from "../Images/partner-white/partner2.png";
import partner3 from "../Images/partner-white/partner3.png";
import partner4 from "../Images/partner-white/partner4.png";
import partner5 from "../Images/partner-white/partner5.png";
import partner6 from "../Images/partner-white/partner6.png";
import partner7 from "../Images/partner-white/partner7.png";
import partner8 from "../Images/partner-white/partner8.png";
import partner9 from "../Images/partner-white/partner9.png";
import logo from "../Images/logo-white.png";
import logoagency from "../Images/logo-agency.png";
import logostudio from "../Images/logo-studio.png";
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import { FaBehance } from "react-icons/fa6";
import { FiYoutube, FiPhone, FiMapPin } from "react-icons/fi";
import CustomInput from "../components/input/CustomInput";
import emailjs from "@emailjs/browser";
import Header from "../components/header/Header";
import { useLocation } from "react-router-dom";

const Contact = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [title, setTitle] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [budget, setBudget] = useState("");
  const [partnershipType, setPartnershipType] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const location = useLocation();
  const toast = useToast();
  const id = "toast";

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location]);

  const submitHandler = () => {
    if (
      firstName &&
      lastName &&
      title &&
      company &&
      email &&
      budget &&
      message &&
      partnershipType.length > 0
    ) {
      // setIsDisabled(true);
      const templateParams = {
        firstName: firstName,
        lastName: lastName,
        title: title,
        company: company,
        email: email,
        budget: budget,
        message: message,
        partnershipType: partnershipType.join(", "),
      };
      // console.log(templateParams);
      emailjs
        .send("service_feyboph", "template_b6uplmh", templateParams, {
          publicKey: "chjNtnHit1cffTA5N",
        })
        .then(
          function (response) {
            // console.log("SUCCESS!", response.status, response.text);
            setIsDisabled(false);
            if (!toast.isActive(id)) {
              toast({
                id,
                duration: 2000,
                position: "top",
                status: "success",
                description: "Амжилттай!",
              });
            }
          },
          function (err) {
            // console.log("FAILED...", err);
            setIsDisabled(false);
            if (!toast.isActive(id)) {
              toast({
                id,
                duration: 2000,
                position: "top",
                status: "error",
                description: "Алдаа гарлаа!",
              });
            }
          }
        );
    }
  };

  const partnerHandler = (value) => {
    // console.log("partnershipType -->", value, partnershipType);
    if (partnershipType.some((e) => e === value)) {
      setPartnershipType(partnershipType.filter((e) => e !== value));
    } else {
      setPartnershipType([...partnershipType, value]);
    }
  };

  const socialData = [
    {
      icon: FaFacebookF,
      toLink: "https://www.facebook.com/Ideaplusmediacompany?mibextid=ZbWKwL",
    },
    {
      icon: FaInstagram,
      toLink:
        "https://www.instagram.com/ideaplus.studio?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==",
    },
    {
      icon: FiYoutube,
      toLink: "https://youtube.com/@ideaagency?si=dB5KUtRp3j5NCeOG",
    },
    {
      icon: FaBehance,
      toLink:
        "https://www.behance.net/ideaplusagency?fbclid=IwZXh0bgNhZW0CMTAAAR0xKNjuJ3Gnr1nDvfgKUI5f7YGe9SNUHNSMr-rgiKkdCrGp6-AjQGS2r4Y_aem_AeT3tq2asFRsYhMS_0Vtlx98OfJSEgLa6Qz55W8Bj2DbHprAeEmW2sxyQTzZYBPDgcPJBASMCFXTUkWeLA9UX0XJ&",
    },
  ];

  return (
    <VStack
      w={"full"}
      minH={"100vh"}
      overflowX={"hidden"}
      pt={"44"}
      spacing={10}
    >
      <Header />
      <Stack
        direction={["column", "column", "column", "row", "row", "row"]}
        w={["xs", "md", "2xl", "3xl", "4xl", "6xl"]}
        pb={"20"}
        spacing={[10, 10, 10, 0, 0, 0]}
      >
        <VStack w={"full"} align={"flex-start"} spacing={10}>
          <Text
            w={"xs"}
            fontSize={[25, 30, 40, 45, 50, 50]}
            fontWeight={"bold"}
            color={"#000"}
            textTransform={"uppercase"}
            lineHeight={"90%"}
          >
            “We have an idea!”
            <Text
              as={"span"}
              fontFamily={"Montserrat"}
              fontSize={30}
              fontWeight={"medium"}
              color={"#000"}
              textTransform={"initial"}
              lineHeight={"90%"}
            >
              {" "}
              We have an
            </Text>
            <Text
              as={"span"}
              fontFamily={"Piedra"}
              fontSize={30}
              fontWeight={"medium"}
              color={"#000"}
              textTransform={"initial"}
              lineHeight={"90%"}
            >
              {" "}
              innovative, creative
            </Text>
            <Text
              as={"span"}
              fontFamily={"Montserrat"}
              fontSize={30}
              fontWeight={"medium"}
              color={"#000"}
              textTransform={"initial"}
              lineHeight={"90%"}
            >
              {" "}
              idea just for you.
            </Text>
          </Text>

          <Box w={"44"} h={"0.5"} bg={"#B81C41"} />

          <Stack
            direction={[
              "column",
              "column",
              "row",
              "column",
              "column",
              "column",
            ]}
            spacing="inherit"
          >
            <VStack
              w={"xs"}
              h={[40, 40, 40, 44, 44, 44]}
              px={10}
              rounded={"md"}
              align={"flex-start"}
              justify={"center"}
              shadow={"0px 0px 10px 2px rgba(0, 0, 0, 0.1)"}
            >
              <Image
                src={logoagency}
                w={[28, 32, 40, 40, 40, 44]}
                objectFit={"contain"}
              />
            </VStack>
            <VStack
              w={"xs"}
              h={[40, 40, 40, 44, 44, 44]}
              px={"10"}
              rounded={"md"}
              align={"flex-start"}
              justify={"center"}
              shadow={"0px 0px 10px 2px rgba(0, 0, 0, 0.1)"}
            >
              <Image
                src={logostudio}
                w={[28, 32, 40, 40, 40, 44]}
                objectFit={"contain"}
              />
            </VStack>
          </Stack>
        </VStack>

        {/* TODO: FORM */}
        <VStack
          w={"full"}
          py={[6, 6, 6, 8, 8, 8]}
          px={[6, 6, 8, 10, 10, 10]}
          // bg={"#f6f6f6"}
          bg={"#fff"}
          rounded={"xl"}
          align={"flex-start"}
          spacing={4}
          pos={"relative"}
          borderWidth={"thin"}
          borderColor={"#B81C41"}
        >
          <Stack
            direction={["column", "row", "row", "row", "row", "row"]}
            w={"full"}
            spacing={4}
          >
            {/* -------------- First name ---------------- */}
            <CustomInput
              title={"First name*"}
              value={firstName}
              onChange={setFirstName}
              required={true}
              bgColor={"#fff"}
            />
            {/* -------------- Last name ---------------- */}
            <CustomInput
              title={"Last name*"}
              value={lastName}
              onChange={setLastName}
              required={true}
              bgColor={"#fff"}
            />
          </Stack>
          {/* -------------- Title ---------------- */}
          <CustomInput
            title={"Title*"}
            value={title}
            onChange={setTitle}
            required={true}
            bgColor={"#fff"}
          />
          {/* -------------- Company ---------------- */}
          <CustomInput
            title={"Company*"}
            value={company}
            onChange={setCompany}
            required={true}
            bgColor={"#fff"}
          />
          {/* -------------- Email ---------------- */}
          <CustomInput
            title={"Email*"}
            value={email}
            onChange={setEmail}
            required={true}
            bgColor={"#fff"}
          />

          {/* -------------- Partnership Type ---------------- */}
          <VStack
            w={"full"}
            align={"flex-start"}
            justify={"flex-start"}
            spacing={1}
          >
            <Text
              fontSize={[11, 11, 11, 12, 14, 14]}
              color={"#000"}
              fontWeight={"medium"}
            >
              Partnership Type*
            </Text>
            <VStack w={"full"} align={"flex-start"}>
              {[
                "Marketing consulting",
                "Digital marketing",
                "Graphic design",
                "Video production",
              ].map((e, i) => (
                <Checkbox
                  key={i}
                  colorScheme="red"
                  size={"md"}
                  value={partnershipType}
                  onChange={() => partnerHandler(e)}
                  isChecked={partnershipType.some((type) => type === e)}
                  css={`
                    > span:first-of-type {
                      box-shadow: unset;
                    }
                  `}
                >
                  <Text
                    fontSize={[11, 11, 11, 12, 14, 14]}
                    color="#000"
                    fontWeight={"normal"}
                  >
                    {e}
                  </Text>
                </Checkbox>
              ))}
            </VStack>
          </VStack>
          {/* -------------- Message ---------------- */}
          <VStack
            w={"full"}
            align={"flex-start"}
            justify={"flex-start"}
            spacing={0}
          >
            <Text
              fontSize={[11, 11, 11, 12, 14, 14]}
              color={"#000"}
              fontWeight={"medium"}
            >
              What is your budget range?*
            </Text>
            <Select
              w={"full"}
              bg={"#fff"}
              size={"md"}
              fontWeight={"normal"}
              fontSize={[11, 11, 11, 12, 14, 14]}
              color={"#000"}
              rounded={"sm"}
              value={budget}
              onChange={(e) => setBudget(e.target.value)}
              _focusVisible={{
                outline: "none",
                borderColor: "#B81C41",
              }}
              _hover={{
                outline: "none",
                borderColor: "#B81C41",
              }}
            >
              <option selected hidden disabled value="">
                -
              </option>
              {[
                "500,000-1,000,000",
                "1,000,000-2,500,000",
                "2,500,000-3,500,000",
                "3,500,000-5,000,000",
                "5,000,000 and above",
              ].map((e, i) => (
                <option key={i} value={e}>
                  {e}
                </option>
              ))}
            </Select>
          </VStack>

          {/* -------------- Budget range ---------------- */}
          <VStack
            w={"full"}
            align={"flex-start"}
            justify={"flex-start"}
            spacing={0}
          >
            <Text
              fontSize={[11, 11, 11, 12, 14, 14]}
              color={"#000"}
              fontWeight={"medium"}
            >
              Message*
            </Text>
            <Textarea
              placeholder="-"
              w={"full"}
              maxH={"20"}
              bg={"#fff"}
              fontWeight={"normal"}
              fontSize={14}
              color={"#000"}
              rounded={"sm"}
              _focusVisible={{
                outline: "none",
                borderColor: "#B81C41",
              }}
              _hover={{
                outline: "none",
              }}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </VStack>

          <HStack
            pos={"absolute"}
            bottom={"-20px"}
            left={10}
            px={"2"}
            bg={"#fff"}
          >
            <Button
              transition={"ease-in-out .3s"}
              bg={"#B81C41"}
              rounded={"full"}
              fontSize={[12, 12, 12, 13, 15, 15]}
              fontWeight={"medium"}
              color={"#fff"}
              textTransform={"uppercase"}
              // mt={"4"}
              px={"6"}
              onClick={() => submitHandler()}
              isDisabled={
                firstName &&
                lastName &&
                title &&
                company &&
                email &&
                budget &&
                message &&
                partnershipType.length > 0 &&
                !isDisabled
                  ? false
                  : true
              }
              _hover={{
                bg: "#A01031",
              }}
            >
              Submit
            </Button>
          </HStack>
        </VStack>
      </Stack>
      <VStack w={"full"} spacing={0}>
        {/* <Box
          width="100vw"
          height="10vh"
          borderRightWidth="2500px"
          borderBottomWidth="100px"
          borderStyle="solid"
          borderColor="transparent"
          borderBottomColor="#030722"
        /> */}
        <VStack
          w={"100vw"}
          minH={["100vh", "100vh", "75vh", "100vh", "100vh"]}
          bgGradient="linear(to-tr, #B81C41 -20%, #030722 30% )"
          // transform="rotate(2deg)"
          align={"center"}
          justify={"center"}
          py={"10"}
          px={"20"}
        >
          <VStack
            w={["xs", "lg", "3xl", "5xl", "5xl", "6xl"]}
            // transform="rotate(-2deg)"
            align={"flex-start"}
            justify={"center"}
            spacing={20}
          >
            <Text
              color={"#fff"}
              fontWeight={"medium"}
              fontSize={35}
              fontFamily="Playfair Display"
              fontStyle={"italic"}
            >
              Clients
            </Text>
            <SimpleGrid
              w={"full"}
              columns={[1, 2, 3, 3, 3, 3]}
              rowGap={[8, 8, 8, 6, 6, 6]}
              columnGap={20}
              alignSelf={"center"}
            >
              {[
                partner1,
                partner2,
                partner3,
                partner4,
                partner5,
                partner6,
                partner7,
                partner8,
                partner9,
              ].map((e, i) => (
                <VStack key={i} w={"full"}>
                  <Image
                    src={e}
                    w={i === 4 ? "48" : "44"}
                    h={[12, 12, 16, 16, 20, 20]}
                    objectFit={"contain"}
                  />
                </VStack>
              ))}
            </SimpleGrid>

            {/* TODO: FOOTER */}
            <Flex
              align={"center"}
              w="full"
              _before={{
                content: '""',
                borderBottom: "1px solid",
                borderColor: "#8A8A8A",
                flexGrow: 1,
                mr: { base: 4, md: 12 },
              }}
              _after={{
                content: '""',
                borderBottom: "1px solid",
                borderColor: "#8A8A8A",
                flexGrow: 1,
                ml: { base: 4, md: 12 },
              }}
            >
              <Image src={logo} w={[32, 32, 40, 40, 44, 44]} />
            </Flex>

            <Stack
              direction={["column", "column", "column", "row", "row", "row"]}
              h="full"
              w={"full"}
              align={"center"}
              justify={"center"}
              spacing={[8, 8, 8, 2, 2, 2]}
            >
              <HStack w={"full"} spacing={4}>
                <Icon as={FiMapPin} w={"5"} h={"5"} color={"#B81C41"} />
                <Text
                  color={"#fff"}
                  fontWeight={"medium"}
                  fontSize={[13, 13, 13, 13, 15, 15]}
                >
                  MGG Properties building, #201 Chin Van Khanddorj street, 2nd
                  khoroo, Sukhbaatar district Ulaanbaatar,Mongolia,14251
                </Text>
              </HStack>
              <HStack
                w={"full"}
                spacing={4}
                align={"center"}
                justify={[
                  "start",
                  "start",
                  "start",
                  "center",
                  "center",
                  "center",
                ]}
                alignSelf="start"
              >
                <Icon as={FiPhone} w={"5"} h={"5"} color={"#B81C41"} />
                <VStack spacing={0}>
                  <Text
                    color={"#fff"}
                    fontWeight={"medium"}
                    fontSize={[13, 13, 13, 13, 15, 15]}
                  >
                    +976 99199997
                  </Text>
                  <Text
                    color={"#fff"}
                    fontWeight={"medium"}
                    fontSize={[13, 13, 13, 13, 15, 15]}
                  >
                    +976 90794474
                  </Text>
                </VStack>
              </HStack>
              <HStack
                w={"full"}
                spacing={10}
                align={"center"}
                justify={[
                  "center",
                  "center",
                  "center",
                  "flex-end",
                  "flex-end",
                  "flex-end",
                ]}
              >
                {socialData.map((e, i) => (
                  <Link key={i} href={e.toLink} h={"7"} isExternal>
                    <Icon
                      transition={"ease-in-out .3s"}
                      key={i}
                      as={e.icon}
                      w={[6, 6, 6, 6, 7, 7]}
                      h={[6, 6, 6, 6, 7, 7]}
                      color={"#B81C41"}
                      _hover={{
                        color: "#fff",
                      }}
                      cursor={"pointer"}
                    />
                  </Link>
                ))}
              </HStack>
            </Stack>
          </VStack>
        </VStack>
      </VStack>
    </VStack>
  );
};

export default Contact;
