import React, { useEffect, useState } from "react";
import {
  HStack,
  Icon,
  Image,
  VStack,
  Text,
  StackDivider,
  Button,
  Link,
} from "@chakra-ui/react";
import logo from "../../Images/logo-white.png";
import logoblack from "../../Images/logo.png";
import { GoMail } from "react-icons/go";
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import { FaBehance, FaArrowRight } from "react-icons/fa6";
import { FiYoutube } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";

const Header = () => {
  const [navSroll, setNavScroll] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const changeShadow = () => {
    if (window.scrollY >= 120) {
      setNavScroll(true);
    } else {
      setNavScroll(false);
    }
  };

  window.addEventListener("scroll", changeShadow);

  useEffect(() => {
    if (location.hash === "" && location.pathname === "/") {
      navigate("/#home");
    }
  }, [location]);

  const headerData = [
    {
      title: "home",
      toLink: "#home",
    },
    {
      title: "About",
      toLink: "#about",
    },
    {
      title: "What we do",
      toLink: "#wwdo",
    },
    {
      title: "What we did",
      toLink: "#wwdi",
    },
  ];

  useEffect(() => {
    if (location.state && location.state.scrollToHash) {
      const hash = location.hash;
      if (hash) {
        const element = document.querySelector(hash);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }
    }
  }, [location]);

  const socialData = [
    {
      icon: FaFacebookF,
      toLink: "https://www.facebook.com/Ideaplusmediacompany?mibextid=ZbWKwL",
    },
    {
      icon: FaInstagram,
      toLink:
        "https://www.instagram.com/ideaplus.studio?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==",
    },
    {
      icon: FiYoutube,
      toLink: "https://youtube.com/@ideaagency?si=dB5KUtRp3j5NCeOG",
    },
    {
      icon: FaBehance,
      toLink:
        "https://www.behance.net/ideaplusagency?fbclid=IwZXh0bgNhZW0CMTAAAR0xKNjuJ3Gnr1nDvfgKUI5f7YGe9SNUHNSMr-rgiKkdCrGp6-AjQGS2r4Y_aem_AeT3tq2asFRsYhMS_0Vtlx98OfJSEgLa6Qz55W8Bj2DbHprAeEmW2sxyQTzZYBPDgcPJBASMCFXTUkWeLA9UX0XJ&",
    },
  ];

  return (
    <VStack
      transition={"ease-in-out .3s"}
      w={"full"}
      // pos={"absolute"}
      position={navSroll ? "fixed" : "absolute"}
      zIndex={"sticky"}
      bg={navSroll ? "#fff" : "transparent"}
      top={0}
      left={0}
      spacing={0}
      divider={<StackDivider borderColor="#808080" />}
      borderBottomWidth={"thin"}
      borderBottomColor={"#808080"}
      // zIndex={999}
    >
      {/* TODO: TOP section */}
      <HStack
        display={
          navSroll ? "none" : ["none", "none", "none", "none", "none", "flex"]
        }
        w={"full"}
        minH={"10"}
        align={"center"}
        justify={"space-between"}
        px={"8"}
      >
        <HStack>
          <Icon
            as={GoMail}
            color={location.pathname === "/contact" ? "#000" : "#FEFEFE"}
          />
          <Text
            fontSize={15}
            color={location.pathname === "/contact" ? "#000" : "#FEFEFE"}
            fontWeight={"medium"}
          >
            info@ideaplus.mn
          </Text>
        </HStack>
        <HStack
          divider={<StackDivider borderColor="#808080" h={"10"} />}
          spacing={2}
        >
          {socialData.map((e, i) => (
            <Link key={i} href={e.toLink} h={"5"} isExternal>
              <Icon
                transition={"ease-in-out .3s"}
                as={e.icon}
                w={"5"}
                h={"5"}
                color={location.pathname === "/contact" ? "#000" : "#FEFEFE"}
                _hover={{
                  color: "#B81C41",
                }}
                cursor={"pointer"}
              />
            </Link>
          ))}
        </HStack>
      </HStack>

      {/* TODO: BOTTOM section */}
      <HStack
        w={"full"}
        h={"14"}
        align={"center"}
        justify={"space-between"}
        px={[6, 6, 6, 6, 8, 8]}
      >
        <Image
          src={location.pathname === "/contact" || navSroll ? logoblack : logo}
          w={[20, 20, 24, 24, 28, 28]}
          objectFit={"contain"}
          onClick={() => navigate("/")}
          cursor={"pointer"}
        />
        <HStack
          spacing={[3, 3, 8, 8, 16, 16]}
          as={"nav"}
          display={["none", "none", "flex", "flex", "flex", "flex"]}
        >
          {headerData.map((e, i) => (
            // <a key={i} href={e.toLink === "" ? "/" : "/" + e.toLink}>
            <Text
              key={i}
              fontSize={[8, 8, 13, 13, 13, 15]}
              color={
                location.pathname === "/contact" || navSroll
                  ? "#000"
                  : "#FEFEFE"
              }
              fontWeight={"medium"}
              textTransform={"uppercase"}
              pos={"relative"}
              _before={{
                content: '""',
                pos: "absolute",
                bottom: 0,
                right: 0,
                // left: 0,
                w:
                  location.pathname === "/" && location.hash === e.toLink
                    ? "100%"
                    : 0,
                h: "2px",
                bg: "#B81C41",
                transition: "ease-in-out .3s",
              }}
              _hover={{
                _before: { width: "100%", left: 0, right: "auto" },
              }}
              onClick={async () => {
                // navigate(e.toLink)
                if (location.pathname === "/contact") {
                  navigate("/" + e.toLink, {
                    replace: true,
                    state: { scrollToHash: true },
                  });
                } else {
                  navigate(e.toLink, {
                    replace: true,
                    state: { scrollToHash: true },
                  });
                }
              }}
              cursor={"pointer"}
              whiteSpace="nowrap"
            >
              {e.title}
            </Text>
            // </a>
          ))}
        </HStack>
        <Button
          transition={"ease-in-out .3s"}
          bg={"#B81C41"}
          h={"8"}
          rounded={"full"}
          fontSize={[12, 12, 12, 12, 14, 14]}
          fontWeight={"medium"}
          color={"#fff"}
          textTransform={"uppercase"}
          px={[2, 2, 4, 4, 6, 6]}
          rightIcon={<FaArrowRight />}
          onClick={() => navigate("/contact")}
          _hover={{
            bg: "#A01031",
          }}
        >
          Partner with us
        </Button>
      </HStack>

      {/* TODO: PHONE SCREEN HEADER NAVIGATIONS */}
      <HStack
        spacing={[3, 3, 8, 8, 16, 16]}
        as={"nav"}
        display={["flex", "flex", "none", "none", "none", "none"]}
        w="full"
        justify={"space-between"}
        px={6}
        py={3}
      >
        {headerData.map((e, i) => (
          // <a key={i} href={e.toLink === "" ? "/" : "/" + e.toLink}>
          <Text
            key={i}
            fontSize={[13, 13, 13, 13, 15, 15]}
            color={
              location.pathname === "/contact" || navSroll
                ? "#000"
                : ["#FEFEFE"]
            }
            fontWeight={"medium"}
            textTransform={"uppercase"}
            pos={"relative"}
            _before={{
              content: '""',
              pos: "absolute",
              bottom: 0,
              right: 0,
              // left: 0,
              w:
                location.pathname === "/" && location.hash === e.toLink
                  ? "100%"
                  : 0,
              h: "1px",
              bg: "#B81C41",
              transition: "ease-in-out .3s",
            }}
            _hover={{
              _before: { width: "100%", left: 0, right: "auto" },
            }}
            onClick={async () => {
              // navigate(e.toLink)
              if (location.pathname === "/contact") {
                navigate("/" + e.toLink, {
                  replace: true,
                  state: { scrollToHash: true },
                });
              } else {
                navigate(e.toLink, {
                  replace: true,
                  state: { scrollToHash: true },
                });
              }
            }}
            cursor={"pointer"}
            whiteSpace="nowrap"
          >
            {e.title}
          </Text>
          // </a>
        ))}
      </HStack>
    </VStack>
  );
};

export default Header;
