import React from "react";
import { Box, Center, Image } from "@chakra-ui/react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import partner1 from ".././../Images/partner1.png";
import partner2 from ".././../Images/partner2.png";
import partner3 from ".././../Images/partner3.png";
import partner4 from ".././../Images/partner4.png";
import partner5 from ".././../Images/partner5.png";
import partner6 from ".././../Images/partner6.png";
import partner7 from ".././../Images/partner7.png";
import partner8 from ".././../Images/partner8.png";
import partner9 from ".././../Images/partner9.png";

const Partner = () => {
  const settings = {
    pauseOnHover: true,
    infinite: true,
    slidesToShow:
      window.innerWidth < 400
        ? 1
        : window.innerWidth < 600
        ? 2
        : window.innerWidth < 992
        ? 3
        : window.innerWidth < 1280
        ? 4
        : 6,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 3000,
    cssEase: "linear",
  };

  return (
    <Box
      py={[8, 8, 8, 16, 16, 16]}
      bg="#fff"
      w={"100vw"}
      minH={"20"}
      zIndex={998}
    >
      <Slider {...settings}>
        {[
          partner1,
          partner2,
          partner3,
          partner4,
          partner5,
          partner6,
          partner7,
          partner8,
          partner9,
        ].map((e, i) => (
          <Image
            key={i}
            src={e}
            objectFit="contain"
            w={[6, 6, 8, 8, 10, 10]}
            h={
              i === 8
                ? ["64px", "64px", "72px", "72px", "80px", "80px"]
                : [12, 12, 14, 14, 16, 16]
            }
            zIndex={999}
          />
        ))}
      </Slider>
    </Box>
  );
};

export default Partner;
