import React from "react";
import { Text, VStack, Image, HStack, GridItem, Grid } from "@chakra-ui/react";
import Title from "../../components/Title";
import logoPlus from "../../Images/logo-plus.png";
import brush from "../../Images/icons/whatWeDo/brush.png";
import camera from "../../Images/icons/whatWeDo/camera.png";
import megaphone from "../../Images/icons/whatWeDo/megaphone.png";
import phone from "../../Images/icons/whatWeDo/phone.png";

function WhatWeDo() {
  const data = [
    {
      title: "Marketing consulting",
      desc: "Marketing campaign, Communication strategy & planning",
      icon: megaphone,
    },

    {
      title: "Digital Marketing",
      desc: "social Media Development, Website development",
      icon: phone,
    },

    {
      title: "Graphic design",
      desc: "Design concept, Print ad, Brochure,",
      icon: brush,
    },

    {
      title: "Video Production",
      desc: "Brand video, Commercial video, Documentary video, Short video, Event after video, Social video, Product photography",
      icon: camera,
    },
  ];

  return (
    <VStack
      id="wwdo"
      w="100vw"
      minH={["100vh", "65vh", "65vh", "100vh", "100vh", "100vh"]}
      align={"center"}
      justify={"center"}
      spacing={20}
      py={20}
    >
      <Title title={"What we do?"} />

      <Grid
        w={["90%", "90%", "90%", "55%", "55%", "55%"]}
        templateColumns={[
          "repeat(1, 1fr)",
          "repeat(2, 1fr)",
          "repeat(2, 1fr)",
          "repeat(2, 1fr)",
          "repeat(2, 1fr)",
          "repeat(2, 1fr)",
        ]}
        templateRows={[
          "repeat(1, 1fr)",
          "repeat(1, 1fr)",
          "repeat(1, 1fr)",
          "repeat(2, 1fr)",
          "repeat(2, 1fr)",
          "repeat(2, 1fr)",
        ]}
        columnGap={[6, 6, 6, 12, 12, 12]}
        rowGap={[8, 8, 8, 16, 16, 16]}
        pos="relative"
      >
        {data.map((e, i) => (
          <GridItem
            key={Math.random()}
            border={"1px"}
            rounded="xl"
            borderColor="rgba(193,193,199, 0.35)"
            zIndex={10}
            color="white"
            backdropFilter="auto"
            backdropBlur={"9px"}
            w={"full"}
            minH={"60"}
          >
            <HStack
              w={"full"}
              gap={6}
              h="full"
              align={"center"}
              pl={"6"}
              pr={"4"}
            >
              <Image
                src={e.icon}
                objectFit="contain"
                w={["45px", "45px", "45px", "45px", "60px", "60px"]}
                h={["45px", "45px", "45px", "45px", "60px", "60px"]}
              />

              <VStack spacing={4}>
                <Text
                  alignSelf={"start"}
                  fontWeight={"semibold"}
                  fontSize={[23, 23, 23, 23, 26, 26]}
                >
                  {e.title}
                </Text>

                <Text fontWeight={"medium"} fontSize={[17, 17, 17, 17, 20, 20]}>
                  {e.desc}
                </Text>
              </VStack>
            </HStack>
          </GridItem>
        ))}
        <Image
          src={logoPlus}
          objectFit="contain"
          w="170px"
          pos={"absolute"}
          top="-85"
          right={"-85"}
        />
      </Grid>
    </VStack>
  );
}

export default WhatWeDo;
