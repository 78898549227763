import React from "react";
import { Input, Text, VStack } from "@chakra-ui/react";

const CustomInput = ({
  title,
  value,
  onChange,
  type,
  onKeyDown,
  required,
  bgColor,
  placeholder,
}) => {
  return (
    <VStack w={"full"} align={"flex-start"} justify={"flex-start"} spacing={0}>
      <Text
        fontSize={[11, 11, 11, 12, 14, 14]}
        color={"#000"}
        fontWeight={"medium"}
      >
        {title}
      </Text>
      <Input
        h={[8, 8, 8, 8, 10, 10]}
        placeholder={placeholder ? placeholder : "-"}
        w={"full"}
        bg={bgColor ? bgColor : "#F0F0F0"}
        fontWeight={"normal"}
        fontSize={14}
        color={"#000"}
        type={type === "number" ? "number" : "text"}
        rounded={"sm"}
        _focusVisible={{
          outline: "none",
          borderColor: "#B81C41",
        }}
        _hover={{
          outline: "none",
        }}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onKeyDown={onKeyDown}
      />
    </VStack>
  );
};

export default CustomInput;
